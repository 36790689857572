


























import ScholarAttendanceAnalyticsComponent from '@/components/analyticsComponent/scholarAnalyticsComponent/scholarAttendanceAnalyticsComponent/ScholarAttendanceAnalyticsComponent';
export default ScholarAttendanceAnalyticsComponent;
