import APP_CONST from '@/constants/AppConst';
import { Vue, Component, Inject } from 'vue-property-decorator';
import analyticsStore from '@/store/modules/analyticsStore';
import { ScreenText } from '@/lang/ScreenText';
import AnalyticsProgramDropdown from '../../commonComponents/analyticsProgramDropdown/AnalyticsProgramDropdown.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import ExportDropdown from '@/commoncomponents/exportdropdown/exportDropdown.vue';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';

@Component({
  components: {
    'analytics-program-dropdown' : AnalyticsProgramDropdown,
    'export-to-csv': ExportDropdown

  }
})


export default class ScholarAttendanceAnalyticsComponent extends Vue {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

    public masterTabsValue = APP_CONST.ANALYTICS_SCHOLAR_ATTENDANCE_SWITCH_TAB;
    private objScreenText: ScreenText = new ScreenText();
    public isTabletView:boolean = false;
    public localConst:any = APP_CONST;
    /* istanbul ignore next */
    get selectedAttendanceAnalyticsTab(){
      return analyticsStore.selectedAttendanceAnalyticsTabValue;
    }

    get disableCsvButton(){
      return  analyticsStore.disableDownloadButton;

    }

    get selectedProgramData(){
      return analyticsStore.slectedProgram;
    }

    get isGroupsDisabled(){
      const isDisable = this.selectedSiteGroups.length
        ? false
        : true;
      return isDisable;
    }
    get selectedSiteGroups(){
      return analyticsStore.selectedSiteGroups;
    }

    get adaCsvDropdown() {
      return analyticsStore.adaCsvDropdown;
    }

    get isDropdownDisable(){
      return analyticsStore.isDropdownDisable;
    }

    get completionCsvDropdown(){
      return analyticsStore.completionCsvDropdown;
    }

    get disableCsvButtonCompletion(){
      return  analyticsStore.disableDownloadCsvButtonCompletion;
    }

    get isCompletionLineChartVisible(){
      return analyticsStore.isCompletionLineChartVisible;
    }

    get completionBarChartLoaderVisible(){
      return analyticsStore.isCompletionBarChartLoaderVisible;
    }

    get disableProgramDropdownADA(){
      return analyticsStore.isADAProgramdropdownDisabled;
    }

    mounted(){
      analyticsStore.adaCsvEnableDisableItems();
      analyticsStore.completionCsvEnableDisableItems();
      this.isTabletView =APP_UTILITIES.tabletCheck();
      window.addEventListener('resize', APP_UTILITIES.debounce(this.isTablet));
    }

    beforeMount(){
      analyticsStore.mutateScholarNavigationSelection('attendance');

      if(APP_UTILITIES.getFilteredColumns('attendanceSelectedTab')){
        let storedTab: any = APP_UTILITIES.getFilteredColumns('attendanceSelectedTab');
        storedTab = JSON.parse(storedTab);
        analyticsStore.mutateAnalyticsAttendanceTabSwitch(storedTab.name);
        this.$router.push(storedTab.url);
      }
      else{
        analyticsStore.mutateAnalyticsAttendanceTabSwitch('ADA');
        this.$router.push('/analytics/scholar/attendance/ada');
      }
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    tabSwitch(tab: any){
      /* istanbul ignore else */
      APP_UTILITIES.setFilteredColumns('attendanceSelectedTab', tab);
      if(this.selectedAttendanceAnalyticsTab != tab.name){
        analyticsStore.mutateAnalyticsAttendanceTabSwitch(tab.name);
        this.$router.push(tab.url);
      }
    }

    isTablet(){
      this.isTabletView = APP_UTILITIES.tabletCheck();
    }

    selectProgramData(selectedProgram:any){
      APP_UTILITIES.setFilteredColumns('attendanceselectedprogram', selectedProgram);
      analyticsStore.getProgramSites(selectedProgram.programId);
    }

    callapi(arg: any) {
      this.analyticsService.track(analyticsEventNames.ATTENDANCE_REPORT_DOWNLOADED, { file: arg.name });
      analyticsStore.downloadCsv(arg);
    }

    completionCsvDownload(arg: any) {
      analyticsStore.downloadCsvCompletion(arg);
    }
}
